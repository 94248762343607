import './App.css';
import Header from "./components/Header";
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import ServicePage from "./pages/ServicePage";
import FOFabPage from "./pages/FOFabPage";
import AboutPage from "./pages/AboutPage";
import ProjectPage from "./pages/ProjectPage";
import DemoPage from "./pages/DemoPage";
import AppPage from "./pages/AppPage";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import RegisterPage from "./pages/RegisterPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import FileListPage from "./pages/FileListPage";
import PrivateRoute from "./service/PrivateRoute";
import TmpDemoPage from "./pages/TmpDemoPage";
import MiroPage from "./pages/MiroPage";
import TermsConditionPage from "./pages/TermsConditionPage";
import SectionPage from "./pages/SectionPage";
// import Glass4Page from "./pages/Glass4Page";
// import LicensePage from "./pages/LicensePage";
import WastageCalculator from "./pages/WastageCalculator"; // Import PrivateRoute


function App() {
      const apiUrl = process.env.REACT_APP_PROCESSOR_API;
      console.log(apiUrl);
    return (
        <Router>
            <Header/>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/home" element={<HomePage/>}/>
                <Route path="/app" element={<AppPage/>}/> {/* Dynamic username */}
                <Route path="/demo" element={<DemoPage/>}/> {/* Dynamic username */}
                <Route path="/wastage" element={<WastageCalculator/>}/> {/* Dynamic username */}
                <Route path="/service" element={<ServicePage/>}/>
                <Route path="/fofab" element={<FOFabPage/>}/>
                <Route path="/about" element={<AboutPage/>}/>
                <Route path="/project" element={<ProjectPage/>}/>
                <Route path="/terms-condition" element={<TermsConditionPage/>}/>
                <Route path="/miro" element={<MiroPage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/register" element={<RegisterPage/>}/>
                <Route path="/profile" element={<PrivateRoute><ProfilePage/></PrivateRoute>}/> {/* Protected route */}
                <Route path="/verify/:token" element={<VerifyEmailPage/>}/>
                <Route path="/tmp" element={<PrivateRoute><FileListPage/></PrivateRoute>}/>
                <Route path="/tmp/:name" element={<PrivateRoute><TmpDemoPage/></PrivateRoute>}/>
                <Route path="/section/" element={<SectionPage/>}/>
                <Route path="/section/:name" element={<SectionPage/>}/>
                {/*<Route path="calculator/glass" element={<Glass4Page/>}/>*/}
                {/*<Route path="/license" element={<LicensePage/>} />*/}
            </Routes>
        </Router>
    );
}

export default App;
