import React, { useState, useMemo } from 'react';
import axios from 'axios';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import WastageOutputChart from "./WastageOutputChart";


function WastageCalculator() {
    const [inputData, setInputData] = useState([
        { len: 300, qty: 10, type: "wood" },
    ]);
    const [maxContainerLoad, setMaxContainerLoad] = useState(1000);
    const [bladeSize, setBladeSize] = useState(5);
    const [outputData, setOutputData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleAddRow = () => {
        setInputData([...inputData, { len: '', qty: '', type: '' }]);
    };

    const handleChangeInput = (index, field, value) => {
        const newData = [...inputData];
        newData[index][field] = field === 'len' || field === 'qty' ? Number(value) : value;
        setInputData(newData);
    };

    const handleCompute = async () => {
        setIsLoading(true);
        const payload = {
            input_data: inputData,
            max_container_load: Number(maxContainerLoad),
            blade_size: Number(bladeSize)
        };
        const baseUrl = process.env.REACT_APP_PROCESSOR_API;
        try {
            const response = await axios.post(`${baseUrl}/calculator/wastage`, payload);
            setOutputData(response.data.output);
            setIsLoading(false);
        } catch (error) {
            console.error('Error during API call:', error);
            setIsLoading(false);
        }
    };

    // Calculating summary details
    const summary = useMemo(() => {
        const totalContainers = outputData.length;  // Total number of containers

        let totalQuantity = 0;
        let totalOptimization = 0;
        let totalWastage = 0;
        outputData.forEach(container => {
            totalQuantity += container.container.total_load;
            totalWastage += container.container.cut_waste;
            const optimization = ((container.container.total_load / maxContainerLoad)) * 100;
            totalOptimization += optimization;
        });

        return {
            totalContainers,
            totalOptimization: outputData.length > 0 ? totalOptimization / outputData.length : 0,
            totalWastage
        };
    }, [outputData, maxContainerLoad]);


    return (
        <div className="flex flex-wrap">
            <div className="w-full md:w-1/4 p-4 bg-white rounded-lg shadow">
                <div className="pb-4">
                    <div className="font-bold">Summary Report</div>
                    <table className=" divide-y divide-gray-200 mt-2  w-full ">
                        <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                            <td className=" py-1 whitespace-nowrap font-bold ">
                                Quantity
                            </td>
                            <td className="px-6 py-1 whitespace-nowrap font-bold ">
                                {summary.totalContainers}
                            </td>
                        </tr>
                        <tr>
                            <td className=" py-1 whitespace-nowrap  ">
                                Optimization
                            </td>
                            <td className="px-6 py-1 whitespace-nowrap  ">
                                {summary.totalOptimization.toFixed(0)}%
                            </td>
                        </tr>
                        <tr>
                            <td className=" py-1 whitespace-nowrap  ">
                                Wastage
                            </td>
                            <td className="px-6 py-1 whitespace-nowrap">
                                {summary.totalOptimization ? `${100 - summary.totalOptimization.toFixed(0)}%` : 'N/A'}
                            </td>

                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="mt-6">
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                    <input
                        id="title"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    />
                </div>
                <div className="mt-6">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                    <input
                        id="description"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    />
                </div>
                <div className="mt-6">
                    <label htmlFor="maxContainerLoad" className="block text-sm font-medium text-gray-700">Stock
                        length</label>
                    <input
                        type="number"
                        id="maxContainerLoad"
                        value={maxContainerLoad}
                        onChange={e => setMaxContainerLoad(Number(e.target.value))}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="bladeSize" className="block text-sm font-medium text-gray-700">Blade Size</label>
                    <input
                        type="number"
                        id="bladeSize"
                        value={bladeSize}
                        onChange={e => setBladeSize(Number(e.target.value))}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    />
                </div>
                {/* Headers for each Input Field */}
                <div className="mb-2 flex justify-between text-sm font-bold text-gray-700">
                    <div className="flex-1 px-2 ">Cut Length</div>
                    <div className="flex-1 px-2 ">Quantity</div>
                </div>

                {/* Input fields mapped from inputData */}
                {inputData.map((item, index) => (
                    <div key={index} className="mb-4 flex space-x-2">
                        <input
                            type="number"
                            placeholder="Length"
                            className="flex-1 p-2 border rounded"
                            value={item.len}
                            onChange={e => handleChangeInput(index, 'len', e.target.value)}
                        />
                        <input
                            type="number"
                            placeholder="Quantity"
                            className="flex-1 p-2 border rounded"
                            value={item.qty}
                            onChange={e => handleChangeInput(index, 'qty', e.target.value)}
                        />

                    </div>
                ))}

                <button onClick={handleAddRow}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Add Row
                </button>
                <button onClick={handleCompute}
                        className={`ml-2 ${isLoading ? 'bg-gray-500' : 'bg-green-500 hover:bg-green-700'} text-white font-bold py-2 px-4 rounded`}
                        disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Compute'}
                </button>


            </div>
            <div className="w-full md:w-3/4 p-4">
                {outputData.length > 0 && (
                    <table className="table-auto w-full">
                        <thead>
                        <tr className="bg-gray-200">
                            <th className="px-4 py-2">Stock #</th>
                            <th className="px-4 py-2">Cut Sequence</th>
                            <th className="px-4 py-2">Used Length</th>
                            <th className="px-4 py-2">Unused Length</th>
                            <th className="px-4 py-2">Cut Wastage</th>
                            <th className="px-4 py-2">Optimization</th>
                        </tr>
                        </thead>
                        <tbody>
                        {outputData.map((container, index) => (
                            <tr key={index} className="bg-white border-b">
                                <td className="px-4 py-2">{container.container.id}</td>
                                <td className="px-4 py-2">{container.container.output.join(', ')}</td>
                                <td className="px-4 py-2">{container.container.total_load}</td>
                                <td className="px-4 py-2">{container.container.unused_length}</td>
                                <td className="px-4 py-2">{container.container.cut_waste}</td>
                                <td className="px-4 py-2">
                                    {((container.container.total_load / maxContainerLoad) * 100).toFixed(0)}%
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}

                <WastageOutputChart output={outputData}></WastageOutputChart>
            </div>


        </div>
    );
}

export default WastageCalculator;


