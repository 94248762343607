import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList
} from 'recharts';

const WastageOutputChart = ({ output }) => {


      const minHeightPerBar = 50;
    const baseHeight = 100; // Base height to account for axis labels, legend, etc.
    const calculatedHeight = output.length * minHeightPerBar + baseHeight;


    // Extracting unique keys for bars based on the output array lengths
    const barDataKeys = [];
    output.forEach(container => {
        container.container.output.forEach((_, index) => {
            const key = `output${index}`;
            if (!barDataKeys.includes(key)) {
                barDataKeys.push(key);
            }
        });
    });

    // Transforming data for the chart
    const chartData = output.map((container, index) => {
        const dataEntry = { containerId: `${index + 1}` }; // Now using just the index for labeling
        container.container.output.forEach((item, index) => {
            dataEntry[`output${index}`] = item;
        });
        return dataEntry;
    });

    return (
<ResponsiveContainer width="100%" height={calculatedHeight} style={{ backgroundColor: '#f5f5f5' }}>
            <BarChart layout="vertical" data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis type="category" dataKey="containerId" tickLine={false} axisLine={false} />
                <Tooltip />
                {/*<Legend />*/}
                {barDataKeys.map((key, index) => (
                    <Bar key={key} dataKey={key} stackId="a" fill="#8884d8">
                        <LabelList dataKey={key} position="insideLeft" style={{ fill: 'white' }} />
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

// Helper function to assign colors to bars
const getBarColor = (index) => {
    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#ffbb28'];
    return colors[index % colors.length];
};

export default WastageOutputChart;
